﻿module SharedElements

open AllPages
open Shared

open Elmish
open Fable.Core.JsInterop
open Feliz.Router


importAll "./css/tailwind.css"

type SharedModel = { Title: string }

type Msg =
    | NavigateTo of WebPages.PageName
    | Logout
    | BackToStart

let initModel: SharedModel = { Title = "Kung Fu Renaissance" }

let init () = initModel, Cmd.none

let update (msg: Msg) (model: SharedModel) (globalModel: GlobalModel) =
    match msg with
    | NavigateTo page ->
        scrollToTop
        let pageName = WebPages.getPageName page
        model, globalModel, Cmd.navigate pageName
    | Logout ->
        Browser.WebStorage.localStorage.setItem ("UserName", "")
        Browser.WebStorage.localStorage.setItem ("UserAccess", "false")
        model,
        { globalModel with
            User = User.create (UserName.setValue "none") "none"
            UserHasLocalAccess = false },
        Cmd.ofMsg (NavigateTo WebPages.pages.StartPage)
    | BackToStart ->
        scrollToTop
        model, globalModel, Cmd.navigate ()

open Feliz
open Feliz.DaisyUI

let globalNavbar (model: SharedModel) (globalModel: GlobalModel) dispatch =
    Daisy.navbar [
        //        theme.cyberpunk // <-- this is the theme
        prop.className
            "mb-2 shadow-lg text-primary rounded-box backdrop-blur-sm first:bg-accent first:opacity-80 hover:opacity-100"
        prop.children [
            Daisy.navbarStart [
                Daisy.dropdown [
                    dropdown.hover
                    prop.children [
                        Daisy.button.button [
                            button.square
                            button.ghost
                            prop.children [
                                Html.i [ prop.className "fas fa-bars" ]
                            ]
                        ]
                        Daisy.dropdownContent [
                            prop.className "p-2 shadow menu bg-base-100 rounded-box w-52 fixed left-0"
                            prop.tabIndex 0
                            prop.children [
                                Html.li [
                                    Html.a [
                                        prop.text "Back to start"
                                        prop.onClick (fun _ -> BackToStart |> dispatch)
                                    ]
                                ]
                                if globalModel.UserHasLocalAccess then
                                    Html.li [
                                        Html.a [
                                            prop.text "AccountabilityPage"
                                            prop.onClick (fun _ -> dispatch (NavigateTo WebPages.pages.AccountabilityPage))
                                        ]
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
            Daisy.navbarCenter [
                prop.children [
                    Html.span [
                        prop.text model.Title
                        prop.onClick (fun _ -> dispatch (NavigateTo WebPages.pages.StartPage))
                    ]
                ]
            ]
            Daisy.navbarEnd [
                Daisy.dropdown [
                    dropdown.hover
                    prop.children [
                        Daisy.button.button [
                            button.square
                            button.ghost
                            prop.children [
                                Html.i [
                                    prop.className "fas fa-ellipsis-h"
                                ]
                            ]
                        ]
                        Daisy.dropdownContent [
                            prop.className "p-2 shadow menu bg-base-100 rounded-box w-52 fixed right-0"
                            prop.tabIndex 0
                            prop.children [
                                if globalModel.UserHasLocalAccess then
                                    Html.li [
                                        Html.a [
                                            prop.text "Log out"
                                            prop.onClick (fun _ -> dispatch Logout)
                                            //TODO Reset UserName
                                            //TODO Make Global Messages
                                            ]
                                    ]
                                else
                                    Html.li [
                                        Html.a [
                                            prop.text "Log in"
                                            prop.onClick (fun _ -> dispatch (NavigateTo WebPages.pages.LoginPage))
                                        ]
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let globalFooter (model: SharedModel) (globalModel: GlobalModel) dispatch =
    Daisy.footer [
        prop.className "p-10 bg-primary text-neutral-content"
        prop.children [
            Html.div [
                Daisy.footerTitle "Services"
                Daisy.link [
                    link.hover
                    prop.text "None, so far"
                ]
            ]
            Html.div [
                Daisy.footerTitle "Reigam"
                Daisy.link [
                    link.hover
                    prop.text "About me"
                ]
            ]
            Html.div [
                Daisy.footerTitle "Legal"
                Daisy.link [
                    link.hover
                    prop.text "Legal"
                ]
                Daisy.link [
                    link.hover
                    prop.text "Cookie policy"
                ]
            ]
        ]
    ]

let globalBackButton (model: SharedModel) (globalModel: GlobalModel) dispatch (buttonText: string) =
    Daisy.button.button [
        button.primary
        prop.className "hover:bg-sky-700"
        prop.text buttonText
        prop.onClick (fun _ -> dispatch BackToStart)
    ]