﻿namespace Shared

open System

(*Accountability*)
[<CLIMutable>]
type Accountability = {
    Id: Guid
    TimeStamp: DateTime
    Description: string
    TextAndCode: (bool * bool) option }

module Accountability =
    let isValid description =
        String.IsNullOrWhiteSpace description |> not

    let create description textAndCode =
        { Id = Guid.NewGuid()
          TimeStamp = DateTime.Now
          Description = description
          TextAndCode = textAndCode }

type IAccountabilitiesApi = {
    getAccountabilities: unit -> Async<Accountability list>
    addAccountability: Accountability -> Async<Accountability>
    deleteAccountability: Guid -> Async<unit>
}