/// You can use this page as a template for your own custom pages.
module AccountabilityPage

open AllPages

open Elmish
open Fable.Core.JsInterop
open Shared
open Feliz.Router

importAll "./css/tailwind.css"

let thisPage: WebPages.PageName = WebPages.pages.AccountabilityPage

type Model = {
    SharedElements: SharedElements.SharedModel
    DescriptionInput: string
    TextAndCodeInput: (bool * bool) option
    SuperAccess: bool }

type Msg =
    | SharedElementsMsg of SharedElements.Msg
    | GetAccountabilities
    | GotAccountabilities of Accountability list
    | NavigateTo of WebPages.PageName
    | BackToStart
    | SetDescriptionInput of string
    | AddAcc
    | AddedAcc of Accountability
    | DeleteAcc of Accountability

let initModel: Model = {
    SharedElements = fst (SharedElements.init ())
    DescriptionInput = ""
    TextAndCodeInput = None
    // TODO put this server side
    SuperAccess = Browser.WebStorage.localStorage.getItem "UserName" = "SuperDuperUser" }

let init () =
    initModel, Cmd.ofMsg GetAccountabilities

let update (msg: Msg) (model: Model) (globalModel: GlobalModel) =
    match msg with
    | SharedElementsMsg m ->
        let l, g, c = SharedElements.update m model.SharedElements globalModel
        { model with SharedElements = l }, g, (Cmd.map SharedElementsMsg c)
    | GetAccountabilities ->
        let cmd = Cmd.OfAsync.perform accountabilitiesApi.getAccountabilities () GotAccountabilities
        model, globalModel, cmd
    | GotAccountabilities accountabilities ->
        model, { globalModel with Accountabilities = accountabilities}, Cmd.none
    | NavigateTo page ->
        let pageName = WebPages.getPageName page
        model, globalModel, Cmd.navigate pageName
    | BackToStart -> model, globalModel, Cmd.navigate ()
    | SetDescriptionInput s -> {model with DescriptionInput = s}, globalModel, Cmd.none
    | AddAcc ->
        let input =
            if model.DescriptionInput = "" then "No Motto today"
            else model.DescriptionInput
        let acc = Accountability.create input None
        let cmd = Cmd.OfAsync.perform accountabilitiesApi.addAccountability acc AddedAcc
        { model with DescriptionInput = "" }, globalModel, cmd
    | AddedAcc acc -> model, {globalModel with Accountabilities = globalModel.Accountabilities @ [ acc ]}, Cmd.none
    | DeleteAcc acc ->
        Cmd.OfAsync.start (accountabilitiesApi.deleteAccountability(acc.Id))
        let accList = globalModel.Accountabilities |> List.filter (fun a -> a.Id <> acc.Id)
        model, { globalModel with Accountabilities = accList },  Cmd.none
    // | TODO Show Add Button only if super user access

open Feliz
open Feliz.DaisyUI

let view (model: Model) (globalModel: GlobalModel) dispatch =

    let accDescriptionInputField =
        Daisy.input [
            input.bordered
            input.primary
            prop.value model.DescriptionInput
            prop.placeholder "Enter a Motto of the Day (if you want)"
            prop.onChange (fun x -> SetDescriptionInput x |> dispatch) ]

    let accCreateButton =
        Daisy.button.button [
            button.secondary
            prop.className "absolute top-0 right-0 rounded-l-none"
            prop.text "Punch in"
            prop.onClick (fun _ -> AddAcc |> dispatch)
        ]

    let accDeleteButton (acc: Accountability) =
        Daisy.button.button [
            button.primary
            // prop.className "relative inset-y-0 right-0 rounded-l-none"
            prop.text "Del"
            prop.onClick (fun _ -> DeleteAcc acc |> dispatch)
        ]

    let accText (acc: Accountability) =
         Html.li [
             // prop.className "m-3 border-2 rounded-md bg-slate-50"
             prop.text $"{acc.Description}"
         ]

    let accountabilitiesCard =
        Daisy.card [
            prop.className "shadow-lg "
            prop.children [
                if model.SuperAccess then
                    Daisy.formControl [
                        Html.div [
                            prop.className "container flex flex-row w-full"
                            prop.children [
                                accDescriptionInputField
                                accCreateButton
                            ]
                        ]
                    ]
                Daisy.cardBody [
                    Daisy.cardTitle "My Accountabilities"
                    Html.ol [
                        for acc in (globalModel.Accountabilities |> List.rev ) do
                            Daisy.card [
                                prop.className "shadow-lg bg-slate-100 m-3"
                                prop.children [
                                    Daisy.cardTitle $"{acc.TimeStamp}"
                                    Daisy.cardBody [
                                        accText acc
                                    ]
                                    if model.SuperAccess then
                                        Daisy.cardActions [
                                            accDeleteButton acc
                                        ]
                                ]
                            ]
                    ]
                ]
            ]
        ]

    let legacyCard =
        Daisy.card [
            prop.className "shadow-lg m-3"
            prop.children [
                Daisy.cardBody [
                    Daisy.cardTitle "Legacy Accountabilites"
                    Html.ol [
                        Html.li [ prop.text "23/01/06 - 05:35 - worked on kun-ren - 1000 questions "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "23/01/05 - 05:35 - worked on csv type provider - 1000 questions "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "23/01/04 - 05:35 - streamlined file-transfer - 1000 questions "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "23/01/03 - 05:35 - kun-ren.com online - davinci audio normalisation "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "23/01/02 - 05:25 - solved a docker traefik problem - started on davinci resolve "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "23/01/01 - day of"; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "22/12/31 - 05:25 - worked on f# - published to social media "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "22/12/30 - 05:25 - parsed a long text file - prepared next video "; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "22/12/29 - 05:25 - started Accountability API - first youtube video"; prop.className "m-3 border-2 rounded-md"]
                        Html.li [ prop.text "22/12/28 - 04:45 - create this page"; prop.className "m-3 border-2 rounded-md" ]
                    ]
                ]
            ]
        ]

    let heroContent =
        Daisy.heroContent [
            prop.className "container text-center text-neutral-content hero-overlay bg-opacity-60"
            prop.children [
                Html.div [
                    prop.className "max-w-md "
                    prop.children [
                        Html.h1 [
                            prop.className "mb-5 text-5xl font-bold"
                            prop.text "My Accountabilities"
                        ]
                        //descriptionInput
                        accountabilitiesCard
                        legacyCard
                    ]
                ]
            ]
        ]

    let hero =
        Daisy.hero [
            prop.className "min-h-screen flex"
            prop.style [
                style.backgroundImageUrl "https://kungfu-renaissance.com/wp-content/uploads/2022/12/Accountability.png"             ]
            prop.children [
                SharedElements.globalNavbar model.SharedElements globalModel (SharedElementsMsg >> dispatch)
                heroContent ]
        ]

    Html.div [
        prop.children [
            hero
            SharedElements.globalFooter model.SharedElements globalModel (SharedElementsMsg >> dispatch) ] ]