﻿/// You can use this page as a Login for your own custom pages.
module LoginPage

open AllPages

open Elmish
open Fable.Remoting.Client
open AllPages
open Fable.Core.JsInterop
open Feliz.Router
open Shared

importAll "./css/tailwind.css"

let thisPage: WebPages.PageName = WebPages.pages.LoginPage

type Model =
    { SharedElements: SharedElements.SharedModel
      Title: WebPages.PageName
      UserInput: string }

type Msg =
    | SharedElementsMsg of SharedElements.Msg
    | NavigateTo of WebPages.PageName
    | BackToStart
    | UserInputChanged of string
    | CheckUser
    | UserWasChecked of bool


let initModel: Model =
    { SharedElements = fst (SharedElements.init ())
      Title = thisPage
      UserInput = "" } //(Browser.WebStorage.localStorage.getItem "UserName")}


let init () = initModel, Cmd.none

let update (msg: Msg) (model: Model) (globalModel: GlobalModel) =
    match msg with
    | SharedElementsMsg m ->
        let l, g, c = SharedElements.update m model.SharedElements globalModel
        { model with SharedElements = l }, g, (Cmd.map SharedElementsMsg c)
    | NavigateTo page ->
        scrollToTop
        let pageName = WebPages.getPageName page
        model, globalModel, Cmd.navigate pageName
    | BackToStart ->
        scrollToTop
        model, globalModel, Cmd.navigate ()
    | UserInputChanged user ->
        { model with UserInput = user }, globalModel, Cmd.none
    | CheckUser ->
        let cmd = Cmd.OfAsync.perform usersApi.validateUser (UserName.setValue model.UserInput) UserWasChecked
        model, globalModel, cmd
    | UserWasChecked valid ->
        if valid then
            Browser.WebStorage.localStorage.setItem ("UserName", model.UserInput)
            Browser.WebStorage.localStorage.setItem ("UserAccess", "true")
        else
            Browser.WebStorage.localStorage.setItem ("UserName", "")
            Browser.WebStorage.localStorage.setItem ("UserAccess", "false")
        {model with UserInput = ""},
        {globalModel with UserHasLocalAccess = valid },
        Cmd.none

open Feliz
open Feliz.DaisyUI

let view (model: Model) (globalModel: GlobalModel) dispatch =
    Html.div [
        prop.children [
            Daisy.hero [
                prop.className "min-h-screen flex"
                prop.style [
                    style.backgroundImageUrl "https://kungfu-renaissance.com/wp-content/uploads/2022/12/Morning.png"
                ]
                prop.children [
                    SharedElements.globalNavbar model.SharedElements globalModel (SharedElementsMsg >> dispatch)
                    Daisy.heroContent [
                        prop.className "container  text-center text-neutral-content hero-overlay bg-opacity-60"
                        prop.children [
                            Html.div [
                                prop.className "max-w-md "
                                prop.children [
                                    Html.h1 [
                                        prop.className "mb-5 text-5xl font-bold"
                                        prop.text "Log ME In"
                                    ]
                                    Daisy.card [
                                        prop.className "mt-3"
                                        card.bordered
                                        prop.children [
                                            Daisy.formControl [
                                                Daisy.label [Daisy.labelText "Enter 'user' to get access to the Accountability Page!"]
                                                Daisy.input [
                                                    input.bordered
                                                    prop.value model.UserInput
                                                    prop.placeholder "Username"
                                                    prop.onTextChange (fun s ->
                                                        dispatch (UserInputChanged s))
                                                ]
                                            ]
                                            Daisy.button.button [
                                                button.primary
                                                prop.className "mt-3 hover:bg-sky-700"
                                                prop.text "Enter"
                                                if (model.UserInput = "") then
                                                    button.disabled
                                                prop.onClick (fun _ -> CheckUser |> dispatch)
                                            ]
                                            Daisy.button.button [
                                                button.primary
                                                prop.className "mt-3 hover:bg-sky-700"
                                                prop.text "Got to Accountability Page"
                                                if not globalModel.UserHasLocalAccess then
                                                    button.disabled
                                                prop.onClick (fun _ -> dispatch (NavigateTo WebPages.pages.AccountabilityPage))
                                            ]
                                        ]
                                    ]

                                ]
                            ]
                        ]
                    ]
                ]
            ]
            SharedElements.globalFooter model.SharedElements globalModel (SharedElementsMsg >> dispatch)
        ]
    ]