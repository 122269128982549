module AllPages

open Shared

open Fable.Remoting.Client
open Fable.Core.JsInterop
open Feliz.Router

importAll "./css/tailwind.css"

(*WEB Pages*)
module WebPages =
    type PageName = PageName of string
    let getPageName (PageName str) = str

    type Pages =
        { TemplatePage: PageName
          LoginPage: PageName
          StartPage: PageName
          TodoPage: PageName
          AccountabilityPage: PageName }

    let pages: Pages =
        { TemplatePage = PageName "Template Page"
          LoginPage = PageName "Login Page"
          StartPage = PageName "Start Page"
          TodoPage = PageName "Todo Page"
          AccountabilityPage = PageName "Accountability Page" }

(*APIs*)
let todosApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<ITodosApi>

let accountabilitiesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IAccountabilitiesApi>

let usersApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IUsersApi>


(*global Model*)
type GlobalModel =
    { CurrentUrl: string list
      User: User
      UserHasLocalAccess: bool
      Todos: Todo list
      Accountabilities: Accountability list }

// Integrating javaScript commands
open Fable.Core

/// implements javaScript scrollToTop function
[<Emit("window.scrollTo(0, 0)")>]
let scrollToTop: unit = jsNative