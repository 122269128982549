module Index

open Elmish
open Feliz.Router
open AllPages
open Shared

type Model =
    { Global: GlobalModel
      LoginPage:LoginPage.Model
      StartPage: StartPage.Model
      TodoPage: TodoPage.Model
      AccountabilityPage: AccountabilityPage.Model }

let initModel: Model =
    { Global =
        { CurrentUrl = Router.currentUrl ()
          User = User.create (UserName.setValue (Browser.WebStorage.localStorage.getItem "UserName")) "test"
          UserHasLocalAccess = Browser.WebStorage.localStorage.getItem "UserAccess" = "true"
          Todos = []
          Accountabilities = [] }
      LoginPage = fst (LoginPage.init ())
      StartPage = fst (StartPage.init ())
      TodoPage = fst (TodoPage.init ())
      AccountabilityPage = fst (AccountabilityPage.init ())}
    //TODO: maybe init globalModel? no more need for newGlobal in Update

type Msg =
    | LoginPageMsg of LoginPage.Msg
    | StartPageMsg of StartPage.Msg
    | TodoPageMsg of TodoPage.Msg
    | AccountabilityPageMsg of AccountabilityPage.Msg
    | UrlChanged of string list
    | GetTodos
    | GotTodos of Todo list
    | GetAccountabilities
    | GotAccountabilities of Accountability list

let init () : Model * Cmd<Msg> =
    let cmd =
        Cmd.ofMsg GetAccountabilities
        //Cmd.OfAsync.perform accountabilitiesApi.getAccountabilities () GotAccountabilities
    let model = initModel
    model, cmd

let update (msg: Msg) (model: Model) =
    match msg with
    | LoginPageMsg m ->
        let l, g, c = LoginPage.update m model.LoginPage model.Global
        { model with LoginPage = l; Global = g }, (Cmd.map LoginPageMsg c)
    | StartPageMsg m ->
        let l, g, c = StartPage.update m model.StartPage model.Global
        { model with StartPage = l; Global = g }, (Cmd.map StartPageMsg c)
    | TodoPageMsg m ->
        let l, g, c = TodoPage.update m model.TodoPage model.Global
        { model with TodoPage = l; Global = g }, (Cmd.map TodoPageMsg c)
    | AccountabilityPageMsg m ->
        let l, g, c = AccountabilityPage.update m model.AccountabilityPage model.Global
        { model with AccountabilityPage = l; Global = g }, (Cmd.map AccountabilityPageMsg c)
    | UrlChanged segments ->
        let newGlobal: GlobalModel =
            { model.Global with
                CurrentUrl = segments
                User = User.create (UserName.setValue (Browser.WebStorage.localStorage.getItem "UserName")) "test"
            }
        { model with Global = newGlobal }, Cmd.none
    | GetTodos ->
        let cmd = Cmd.OfAsync.perform todosApi.getTodos () GotTodos
        model, cmd
    | GotTodos todos ->
        let newGlobal: GlobalModel =
            { model.Global with
                Todos = todos
                User = User.create (UserName.setValue (Browser.WebStorage.localStorage.getItem "UserName")) "test"
            }
        { model with Global = newGlobal }, Cmd.none
    | GetAccountabilities ->
        let cmd = Cmd.OfAsync.perform accountabilitiesApi.getAccountabilities () GotAccountabilities
        model, cmd
    | GotAccountabilities accountabilities ->
        let newGlobal =
            {model.Global with
                Accountabilities = accountabilities }
                // OldUser = Browser.WebStorage.localStorage.getItem "UserName"
                // OldUserAccess = Browser.WebStorage.localStorage.getItem "UserName" = "user"
                // OldSuperUserAccess = Browser.WebStorage.localStorage.getItem "UserName" = "SuperDuperUser" }
        { model with Global = newGlobal }, Cmd.ofMsg GetTodos

open Feliz

let view (model: Model) (dispatch: Msg -> unit) =
    let currentPage =
        match model.Global.CurrentUrl with
        | [] -> StartPage.view model.StartPage model.Global (StartPageMsg >> dispatch)
        | [ "Start Page" ] -> StartPage.view model.StartPage model.Global (StartPageMsg >> dispatch)
        | [ "Login Page" ] ->  LoginPage.view model.LoginPage model.Global (LoginPageMsg >> dispatch)
        | [ "Todo Page" ] ->
            if model.Global.UserHasLocalAccess then
                TodoPage.view model.TodoPage model.Global (TodoPageMsg >> dispatch)
            //else Html.h1 "Not Logged in"
            else LoginPage.view model.LoginPage model.Global (LoginPageMsg >> dispatch)
        | [ "Accountability Page" ] ->
            if model.Global.UserHasLocalAccess then
                AccountabilityPage.view model.AccountabilityPage model.Global (AccountabilityPageMsg >> dispatch)
            //else Html.h1 "Not Logged in"
            else LoginPage.view model.LoginPage model.Global (LoginPageMsg >> dispatch)
        | _ -> Html.h1 "Something went wrong. Page not found"

        //        | [ "users"; Route.Query [ "id", Route.Int userId ] ] -> //// KEEP FOR FUTURE Queries
        //            Html.h1 (sprintf "Showing user %d" userId)


    React.router [
        router.onUrlChanged (UrlChanged >> dispatch)
        router.children currentPage
    ]