﻿namespace Shared

open System

(*User*)
type UserName = UserName of String
module UserName =
    let setValue s = UserName s
    let getValue (UserName s) = s

type Password = Password of string
module Password =
    let set password = Password password

[<CLIMutable>]
type User = { Id: Guid; UserName: UserName; Password: Password }

module User =
    let isValid (userName: UserName) =
        String.IsNullOrWhiteSpace (UserName.getValue userName) |> not

    let create (userName: UserName) (userPassword: string) =
        { Id = Guid.NewGuid()
          UserName = userName
          Password = Password.set userPassword }

type IUsersApi =
    { addUser: User -> Async<User>
      validateUser: UserName -> Async<bool> }